import * as React from 'react'

import { LANG, t } from '../i18n'
import { SECTIONS } from '../constants'

interface Props {
  lang: LANG
}

const link = (href: string, str?: React.ReactNode) => <a target="_blank" href={href}>{str ? str : <i className="fa fa-fw fa-external-link" />}</a>

export default class Wedding extends React.Component<Props> {
  render() {
    const { lang } = this.props
    const section = SECTIONS.wedding

    return <section id={section} className={`${section}-section text-justify`}>
      <h2 className="text-center">{t.sections[section](lang)}</h2>

      <div className="container">
        <div className="row">
          <div className="col-md my-4 text-center">
            <h3 className="mb-4">
              <i className="fa fa-fw fa-globe" />{' '}
              {t.where(lang)}
            </h3>
            <p>
              <i className="fa fa-fw fa-fort-awesome" />{' '}
              Altamount Manor
              <br />

              <i className="fa fa-fw fa-map-marker" />{' '}
              {link('https://goo.gl/maps/kL5VxxJ2kgp', <>
                Coupar Angus Rd, Blairgowrie and Rattray<br />
                Blairgowrie PH10 6JN, Scotland, UK
              </>)}
            </p>

            <p>
              {link('https://goo.gl/maps/kL5VxxJ2kgp', <img
                src="../../img/map.png"
                className="rounded img-thumbnail img-fluid"
                alt="Map of the area"
              />)}
            </p>
          </div>

          <div className="col-md my-4 text-center d-flex flex-column justify-content-between">{/* use flex to vertically center table */}
            <h3 className="mb-4">
              <i className="fa fa-fw fa-calendar" />{' '}
              {t.when(lang)}
            </h3>

            <table className="table table-sm">
              <tbody>
                <tr><td>
                  <div className="row py-2">
                    <div className="col-4 d-flex flex-column justify-content-center"><strong>{t.datefri(lang)}</strong></div>
                    <div className="col-8 d-flex flex-column justify-content-center">{t.eveningsnack(lang)}</div>
                  </div>
                </td></tr>
                <tr><td>
                  <div className="row py-2">
                    <div className="col-4 d-flex flex-column justify-content-center"><strong>{t.datesat(lang)}</strong></div>
                    <div className="col-8 d-flex flex-column justify-content-center">{t.wedding(lang)}</div>
                  </div>
                </td></tr>
                <tr><td>
                  <div className="row py-2">
                    <div className="col-4 d-flex flex-column justify-content-center"><strong>{t.datesun(lang)}</strong></div>
                    <div className="col-8 d-flex flex-column justify-content-center">{t.brunch(lang)}</div>
                  </div>
                </td></tr>
              </tbody>
            </table>

            <p>{/* this child also allows to vertically center the table */}
              {t.programcomments(lang)}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md my-4 text-center">
            <h3 className="mb-4">
              <i className="fa fa-fw fa-info-circle" />{' '}
              {t.contact(lang)}
            </h3>

            <p>
              {t.contacttext(lang)}
            </p>

            <div className="row">
              <div className="col-sm text-center">
                <p>
                  <strong>Aymeric GuÃ©dy</strong> (FR, EN)<br />
                  aymeric.guedy at gmail.com<br />
                  +33 6 61 03 78 12
                </p>

                <p>
                  <strong>Lucas Duboc</strong>  (FR, EN, ES)<br />
                  duboc.lucas at gmail.com<br />
                  +33 6 30 29 47 60
                </p>
              </div>

              <div className="col-sm text-center">
                <p>
                  <strong>Daniel GarcÃ­a</strong> (ES, EN, FR)<br />
                  ugliku at gmail.com<br />
                  +34 651 075 135
                </p>
                <p>
                  <strong>Laura JerÃ³nimo</strong> (ES)<br />
                  laurajb1988 at hotmail.com<br />
                  +34 679 116 966
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
}
