import axios from 'axios'

// https://stackoverflow.com/questions/42533264/getting-error-promise-is-undefined-in-ie11
import { polyfill } from 'es6-promise'
polyfill()

export const UPLOAD_BASIC_AUTH_USER = 'guest'
export const UPLOAD_URL_PREFIX = '/upload'
export const UPLOAD_ENDPOINT = `${UPLOAD_URL_PREFIX}/files/` // final '/' matters

export const makeAuthHeaders = (password: string) => ({
  authorization: `Basic ${btoa(`${UPLOAD_BASIC_AUTH_USER}:${password}`)}`
})

export enum UPLOAD_AUTH_STATUS {
  SUCCESS = 'SUCCESS',
  UNAUTHORIZED = 'UNAUTHORIZED',
  CLIENT_ERROR = 'CLIENT_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export const uploadAuthenticate = async (password: string) => {
  try {
    console.log('upload authenticate')
    await axios.get(`${UPLOAD_URL_PREFIX}/`, { headers: makeAuthHeaders(password) }) // final '/' matters as it prevents 302 redirect and auth prompt popup
    console.log('upload authenticate success')
    return UPLOAD_AUTH_STATUS.SUCCESS
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      console.log('upload authenticate error', { status: err.response.status, data: err.response.data })

      if (err.response.status === 401) { return UPLOAD_AUTH_STATUS.UNAUTHORIZED }
      if (err.response.status >= 500) { return UPLOAD_AUTH_STATUS.SERVER_ERROR }
      if (err.response.status >= 400) { return UPLOAD_AUTH_STATUS.CLIENT_ERROR }

      return UPLOAD_AUTH_STATUS.UNKNOWN_ERROR
    }

    // https://github.com/axios/axios/issues/383
    if (err instanceof Error && err.message === 'Network Error') { return UPLOAD_AUTH_STATUS.NETWORK_ERROR }

    throw err
  }
}
