import * as React from 'react'

import { LANG, t } from '../i18n'
import { SECTIONS } from '../constants'

interface Props {
  setLang: (lang: LANG) => void
  lang: LANG
}

export default class Navbar extends React.Component<Props> {
  render() {
    const { lang } = this.props

    return <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
      <a className="navbar-brand js-scroll-trigger brand" href="#page-top">Isis & Hippo</a>

      <div className="mr-auto p-2">{/*fill*/}</div>

      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ml-auto">
          {(Object.keys(SECTIONS) as SECTIONS[]).map(x => <li key={x} className="nav-item">
            <a className="nav-link js-scroll-trigger" href={`#${x}`}>{t.sections[x](lang)}</a>
          </li>)}
        </ul>
      </div>

      <div className="p-2">{/*separator*/}</div>

      <div className="dropdown d-flex">
        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {lang.toUpperCase()}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {(Object.keys(LANG) as LANG[]).map(x => <div key={x}>
            <button type="button" className="dropdown-item btn btn-sm language-button" onClick={(e) => { e.preventDefault(); this.props.setLang(x) }}>{x.toUpperCase()}</button>
          </div>)}
        </div>
      </div>
    </nav>
  }
}
