"use strict";

const fr_FR = {};
fr_FR.strings = {
  addBulkFilesFailed: {
    '0': 'L\'ajout de %{smart_count} fichier a Ã©chouÃ©',
    '1': 'L\'ajout de %{smart_count} fichiers a Ã©chouÃ©'
  },
  addMore: 'Ajouter d\'autres',
  addMoreFiles: 'Ajouter d\'autres fichiers',
  addingMoreFiles: 'En train d\'ajouter des fichiers',
  allFilesFromFolderNamed: 'Tous les fichiers du dossier %{name}',
  allowAccessDescription: 'Pour prendre des photos ou enregistrer une vidÃ©o avec votre camÃ©ra, veuillez autoriser l\'accÃ¨s Ã  votre camÃ©ra pour ce site.',
  allowAccessTitle: 'Veuillez autoriser l\'accÃ¨s Ã  votre camÃ©ra',
  authenticateWith: 'Se connecter Ã  %{pluginName}',
  authenticateWithTitle: 'Veuillez vous authentifier avec %{pluginName} pour sÃ©lectionner les fichiers',
  back: 'Retour',
  browse: 'naviguer',
  browseFiles: 'naviguer',
  cancel: 'Annuler',
  cancelUpload: 'Annuler tÃ©lÃ©chargement',
  chooseFiles: 'Choisir des fichiers',
  closeModal: 'Fermer FenÃªtre',
  companionError: 'Connexion Ã  Companion a Ã©chouÃ©',
  companionUnauthorizeHint: 'Pour vous dÃ©connecter de votre compte %{provider}, veuillez aller Ã  %{url}',
  complete: 'TerminÃ©',
  connectedToInternet: 'ConnectÃ© Ã  Internet',
  copyLink: 'Copier lien',
  copyLinkToClipboardFallback: 'Copier le lien ci-dessous',
  copyLinkToClipboardSuccess: 'Lien copiÃ©',
  creatingAssembly: 'PrÃ©paration du tÃ©lÃ©chargement...',
  creatingAssemblyFailed: 'Transloadit: Impossible de crÃ©er Assembly',
  dashboardTitle: 'TÃ©lÃ©chargeur de fichier',
  dashboardWindowTitle: 'FenÃªtre de tÃ©lÃ©chargeur de fichier (Appuyez sur echap pour fermer)',
  dataUploadedOfTotal: '%{complete} sur %{total}',
  done: 'TerminÃ©',
  dropHereOr: 'DÃ©poser les fichiers ici ou %{browse}',
  dropHint: 'DÃ©posez vos fichiers ici',
  dropPasteBoth: 'DÃ©poser les fichiers ici, coller ou %{browse}',
  dropPasteFiles: 'DÃ©poser les fichiers ici, coller ou %{browse}',
  dropPasteFolders: 'DÃ©poser les fichiers ici, coller ou %{browse}',
  dropPasteImportBoth: 'DÃ©poser les fichiers ici, coller, %{browse} ou importer de',
  dropPasteImportFiles: 'DÃ©poser les fichiers ici, coller, %{browse} ou importer de',
  dropPasteImportFolders: 'DÃ©poser les fichiers ici, coller, %{browse} ou importer de',
  editFile: 'Modifier le fichier',
  editFileWithFilename: 'Modifier le fichier %{file}',
  editing: 'Modification en cours de %{file}',
  emptyFolderAdded: 'Aucun fichier n\'a Ã©tÃ© ajoutÃ© depuis un dossier vide',
  encoding: 'Traitement...',
  enterCorrectUrl: 'Lien incorrect: Assurez-vous que vous entrez un lien direct vers le fichier',
  enterUrlToImport: 'Entrez le lien pour importer un fichier',
  exceedsSize: 'Ce fichier dÃ©passe la taille maximale autorisÃ©e de %{size}',
  failedToFetch: 'Companion a Ã©chouÃ© Ã  rÃ©cupÃ©rer ce lien, assurez-vous qu\'il est correct',
  failedToUpload: 'Le tÃ©lÃ©chargement de %{file} a Ã©chouÃ©',
  fileSource: 'Fichier source: %{name}',
  filesUploadedOfTotal: {
    '0': '%{complete} sur %{smart_count} fichier tÃ©lÃ©chargÃ©',
    '1': '%{complete} sur %{smart_count} fichiers tÃ©lÃ©chargÃ©s',
    '2': '%{complete} sur %{smart_count} fichiers tÃ©lÃ©chargÃ©s'
  },
  filter: 'Filtrer',
  finishEditingFile: 'Terminer l\'Ã©dition du fichier',
  folderAdded: {
    '0': '%{smart_count} fichier ajoutÃ© de %{folder}',
    '1': '%{smart_count} fichiers ajoutÃ©s de %{folder}',
    '2': '%{smart_count} fichiers ajoutÃ©s de %{folder}'
  },
  generatingThumbnails: 'Generating thumbnails...',
  import: 'Importer',
  importFrom: 'Importer de %{name}',
  loading: 'Chargement...',
  logOut: 'DÃ©connexion',
  micDisabled: 'AccÃ¨s au micro refusÃ© par l\'utilisateur',
  myDevice: 'Mon Appareil',
  noDuplicates: 'Impossible d\'ajouter le fichier "%{fileName}", il existe dÃ©jÃ ',
  noFilesFound: 'Vous n\'avez aucun fichier ou dossier ici',
  noInternetConnection: 'Pas de connexion Ã  Internet',
  noMoreFilesAllowed: 'Impossible d\'ajouter de nouveaux fichiers: en cours de chargement ',
  openFolderNamed: 'Ouvrir %{name}',
  pause: 'Pause',
  pauseUpload: 'Mettre en pause le tÃ©lÃ©chargement',
  paused: 'En pause',
  poweredBy: 'PropulsÃ© par %{uppy}',
  processingXFiles: {
    '0': 'Traitement de %{smart_count} fichier',
    '1': 'Traitement de %{smart_count} fichiers',
    '2': 'Traitement de %{smart_count} fichiers'
  },
  recording: 'Enregistrement',
  recordingLength: 'DurÃ©e d\'enregistrement %{recording_length}',
  recordingStoppedMaxSize: 'L\'enregistrement s\'est arrÃ©tÃ© car la taille du fichier dÃ©passe la limite',
  removeFile: 'Effacer le fichier %{file}',
  resetFilter: 'RÃ©initialiser filtre',
  resume: 'Reprendre',
  resumeUpload: 'Reprendre le tÃ©lÃ©chargement',
  retry: 'RÃ©essayer',
  retryUpload: 'RÃ©essayer le tÃ©lÃ©chargement',
  saveChanges: 'Sauvegarder les modifications',
  selectFileNamed: 'SÃ©lectionner le fichier %{name}',
  selectX: {
    '0': 'SÃ©lectionner %{smart_count}',
    '1': 'SÃ©lectionner %{smart_count}',
    '2': 'SÃ©lectionner %{smart_count}'
  },
  smile: 'Souriez !',
  startRecording: 'Commencer l\'enregistrement vidÃ©o',
  stopRecording: 'ArrÃªter l\'enregistrement vidÃ©o',
  streamActive: 'Stream actif',
  streamPassive: 'Stream passif',
  submitRecordedFile: 'Envoyer la vidÃ©o enregistrÃ©e',
  takePicture: 'Prendre une photo',
  timedOut: 'TÃ©lÃ©chargement bloquÃ© durant %{seconds} secondes, annulation.',
  unselectFileNamed: 'DÃ©sÃ©lectionner le fichier %{name}',
  upload: 'TÃ©lÃ©charger',
  uploadComplete: 'TÃ©lÃ©chargement terminÃ©',
  uploadFailed: 'Le tÃ©lÃ©chargement a Ã©chouÃ©',
  uploadPaused: 'TÃ©lÃ©chargement mis en pause',
  uploadXFiles: {
    '0': 'TÃ©lÃ©charger %{smart_count} fichier',
    '1': 'TÃ©lÃ©charger %{smart_count} fichiers',
    '2': 'TÃ©lÃ©charger %{smart_count} fichiers'
  },
  uploadXNewFiles: {
    '0': 'TÃ©lÃ©charger +%{smart_count} fichier',
    '1': 'TÃ©lÃ©charger +%{smart_count} fichiers',
    '2': 'TÃ©lÃ©charger +%{smart_count} fichiers'
  },
  uploading: 'TÃ©lÃ©chargement en cours',
  uploadingXFiles: {
    '0': 'TÃ©lÃ©chargement de %{smart_count} fichier',
    '1': 'TÃ©lÃ©chargement de %{smart_count} fichiers',
    '2': 'TÃ©lÃ©chargement de %{smart_count} fichiers'
  },
  xFilesSelected: {
    '0': '%{smart_count} fichier sÃ©lectionnÃ©',
    '1': '%{smart_count} fichiers sÃ©lectionnÃ©s',
    '2': '%{smart_count} fichiers sÃ©lectionnÃ©s'
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} autre fichier ajoutÃ©',
    '1': '%{smart_count} autres fichiers ajoutÃ©s',
    '2': '%{smart_count} autres fichiers ajoutÃ©s'
  },
  xTimeLeft: '%{time} restantes',
  youCanOnlyUploadFileTypes: 'Vous pouvez seulement tÃ©lÃ©charger: %{types}',
  youCanOnlyUploadX: {
    '0': 'Vous pouvez seulement tÃ©lÃ©charger %{smart_count} fichier',
    '1': 'Vous pouvez seulement tÃ©lÃ©charger %{smart_count} fichiers',
    '2': 'Vous pouvez seulement tÃ©lÃ©charger %{smart_count} fichiers'
  },
  youHaveToAtLeastSelectX: {
    '0': 'Vous devez sÃ©lectionner au moins %{smart_count} fichier',
    '1': 'Vous devez sÃ©lectionner au moins %{smart_count} fichiers',
    '2': 'Vous devez sÃ©lectionner au moins %{smart_count} fichiers'
  }
};

fr_FR.pluralize = function pluralize(n) {
  if (n === 1) {
    return 0;
  }

  return 1;
};

if (typeof window !== 'undefined' && typeof window.Uppy !== 'undefined') {
  window.Uppy.locales.fr_FR = fr_FR;
}

module.exports = fr_FR;