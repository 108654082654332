import * as Uppy from '@uppy/core'
import * as Tus from '@uppy/tus'
import { Dashboard } from '@uppy/react'
import * as React from 'react'

import { utcRandomID } from '../uuid'

import { UPLOAD_ENDPOINT, makeAuthHeaders } from '../upload-auth'

const UPPY_LOCALES = {
  en: require('@uppy/locales/lib/en_US'),
  fr: require('@uppy/locales/lib/fr_FR'),
  es: require('@uppy/locales/lib/es_ES')
}

import { LANG } from '../i18n'

interface AuthProps {
  username: string
  password: string
}

interface Props extends AuthProps {
  lang: LANG
}

const allowedFileTypes = [
  'image/*',
  'video/*'
]

const USERNAME_KEY = 'x-username'
const SESSION_KEY = 'x-session'

const UploadDashboard: React.FunctionComponent<Props> = ({ username, password, lang }) => {
  const uppy = React.useRef(
    new Uppy.Uppy({
      restrictions: {
        maxNumberOfFiles: 500,
        allowedFileTypes
      },
      meta: {
        [USERNAME_KEY]: username,
        [SESSION_KEY]: utcRandomID()
      }
    })
    .use((Tus as any), { // Type incompatibility due to either .use() or Tus typings...
      overridePatchMethod: true,
      endpoint: UPLOAD_ENDPOINT,
      headers: makeAuthHeaders(password),
      chunkSize: 32 * 1024 * 1024, // 32MB. prevent 413 from router
    })
    .on('complete', (result) => {
      console.log({ result })
    })
  ).current

  React.useEffect(() => {
    return () => uppy.close()
  }, [])

  return <Dashboard
    key={lang/*force update*/}
    uppy={uppy}
    locale={UPPY_LOCALES[lang]}
    showLinkToFileUploadResult={false}
  />
}
export default UploadDashboard
