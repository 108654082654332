import * as React from 'react'
import UploadAuthForm, { AuthProps } from './UploadAuthForm'
import UploadDashboard from './UploadDashboard'

import { LANG, t } from '../i18n'
import { SECTIONS } from '../constants'

interface Props {
  lang: LANG
}

const Upload: React.FunctionComponent<Props> = ({ lang }) => {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')

  const onFormSuccess = React.useCallback(({ username, password }: AuthProps) => {
    setUsername(username)
    setPassword(password)
  }, [])

  const section = SECTIONS.upload

  return <section id={section} className={`${section}-section text-justify invert-colors`}>
    <h2 className="text-center">{t.sections[section](lang)}</h2>

    <div className="container">
      <div className="row">
        <div className="col-md my-4 text-center">
          {t.uploadcontents(lang)}
        </div>
      </div>
    </div>

    <div className="container my-4">
    {username.length === 0 || password.length === 0
      ? <div className="row">
          <div className="col-md-6 col-lg-3 mx-auto text-center">
            <UploadAuthForm onSuccess={onFormSuccess} lang={lang}/>
          </div>
        </div>
      : <div className="row no-gutters x-fade-in">{/*no gutters prevent overflow on small devices */}
          <div className="col" />{/* horizontal centering, empty column will be vertically stacked on small devices */}
          <div className="col">
            <p className="text-center">{t.helloname(username)(lang)}</p>
            <UploadDashboard {...{ username, password }} lang={lang} />
          </div>
          <div className="col" />{/* horizontal centering, empty column will be vertically stacked on small devices */}
        </div>}
    </div>
  </section>
}
export default Upload
