import * as React from 'react'

interface Props {
  src: string
  alt: string
  caption: string
}

interface State {
  focus: boolean
}

const makeModal = (props: Props, onClose: () => void) => <>
  <div
    className="modal d-block" // d-block: we control modal appearance ourselves
    style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
  >
    <div className="h-100 w-100 p-2">
      <div
        className="h-100 w-100"
        style={{
          backgroundImage: `url(${props.src})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'scroll',
          backgroundSize: 'contain',
          cursor: 'pointer'
        }}
        onClick={onClose}
      />
    </div>
  </div>
</>

export default class ImageFigure extends React.Component<Props, State> {
  state = { focus: false }

  render() {
    return <>
      {this.state.focus ? makeModal(this.props, () => this.setState({ focus: false })) : null}

      <figure className="figure">
        <img
          src={this.props.src}
          alt={this.props.alt}
          style={{ width: '100%', maxWidth: '400px', cursor: 'pointer' }}
          className="rounded img-thumbnail img-fluid figure-img"
          onClick={() => this.setState({ focus: true })}
        />
        <figcaption className="figure-caption">{this.props.caption}</figcaption>
      </figure>
    </>
  }
}
