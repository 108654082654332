import * as React from 'react'

import { uploadAuthenticate, UPLOAD_AUTH_STATUS } from '../upload-auth'

export interface AuthProps {
  username: string
  password: string
}

import { LANG, t } from '../i18n'

interface Props {
  lang: LANG
  onSuccess: (_: AuthProps) => void
}

const UploadAuthForm: React.FunctionComponent<Props> = ({ lang, onSuccess }) => {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')

  const [sending, setSending] = React.useState(false)
  const [status, setStatus] = React.useState<UPLOAD_AUTH_STATUS | null>(null)

  const onNameChange = React.useCallback((event: React.ChangeEvent<{ value: string }>) => {
    setUsername(event.target.value)
  }, [])
  const onPasswordChange = React.useCallback((event: React.ChangeEvent<{ value: string }>) => {
    setPassword(event.target.value)
  }, [])

  const onSubmit = React.useCallback((event: React.FormEvent) => {
    event.preventDefault()
    setSending(true)
  }, [username, password])

  const tryAuthenticate = React.useCallback(async () => {
    const s = await uploadAuthenticate(password)
    setSending(false)
    setStatus(s)
  }, [password])

  React.useEffect(() => {
    if (!sending) { return }
    tryAuthenticate()
  }, [sending])

  React.useEffect(() => {
    if (status === UPLOAD_AUTH_STATUS.SUCCESS) {
      onSuccess({ username, password })
    }
  }, [status])

  return <form onSubmit={onSubmit}>
    <div className="form-group">
      <label>{t.name(lang)}</label>
      <input className="form-control text-center" placeholder={t.namePlaceholder(lang) as string} onChange={onNameChange} value={username} required={true} />
    </div>
    <div className="form-group">
      <label>{t.password(lang)}</label>
      <input className="form-control text-center" type="password" placeholder={t.password(lang) as string} onChange={onPasswordChange} value={password} required={true} />
    </div>
    <div className="form-group">
      <button type="submit" className="btn btn-secondary" disabled={sending || status === UPLOAD_AUTH_STATUS.SUCCESS}>
        OK
      </button>
    </div>

    <div className="form-group row">
      <div className="col text-center">
        <div>
          {(() => {
            if (sending) { return <i className="fa fa-fw fa-circle-o-notch fa-spin" /> }
            if (status === null) return null
            if (status === UPLOAD_AUTH_STATUS.SUCCESS) { return <div className="text-success"><i className="fa fa-fw fa-check" /></div> }
            return <div className="text-danger"><i className="fa fa-fw fa-times" /> {status}</div>
          })()}
        </div>
      </div>
    </div>
  </form>
}

export default UploadAuthForm
