import * as React from 'react'

import { LANG, t } from '../i18n'
import { SECTIONS } from '../constants'

interface Props {
  lang: LANG
}

export default class GiftList extends React.Component<Props> {
  render() {
    const { lang } = this.props
    const section = SECTIONS.giftList

    return <section id={section} className={`${section}-section text-justify`}>
      <h2 className="text-center">{t.sections[section](lang)}</h2>

      <div className="container my-4">
        <div className="row">
          <div className="col-md">
            {t.giftlistcontents(lang)}
          </div>
        </div>
      </div>
    </section>
  }
}
