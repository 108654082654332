"use strict";

const {
  h
} = require('preact');

const prettierBytes = require('@transloadit/prettier-bytes');

const truncateString = require('@uppy/utils/lib/truncateString');

const renderFileName = props => {
  // Take up at most 2 lines on any screen
  let maxNameLength; // For very small mobile screens

  if (props.containerWidth <= 352) {
    maxNameLength = 35; // For regular mobile screens
  } else if (props.containerWidth <= 576) {
    maxNameLength = 60; // For desktops
  } else {
    maxNameLength = 30;
  }

  return h("div", {
    className: "uppy-Dashboard-Item-name",
    title: props.file.meta.name
  }, truncateString(props.file.meta.name, maxNameLength));
};

const renderFileSize = props => props.file.size && h("div", {
  className: "uppy-Dashboard-Item-statusSize"
}, prettierBytes(props.file.size));

const ReSelectButton = props => props.file.isGhost && h("span", null, ' \u2022 ', h("button", {
  className: "uppy-u-reset uppy-c-btn uppy-Dashboard-Item-reSelect",
  type: "button",
  onClick: props.toggleAddFilesPanel
}, props.i18n('reSelect')));

const ErrorButton = ({
  file,
  onClick
}) => {
  if (file.error) {
    return h("button", {
      className: "uppy-Dashboard-Item-errorDetails",
      "aria-label": file.error,
      "data-microtip-position": "bottom",
      "data-microtip-size": "medium",
      onClick: onClick,
      type: "button"
    }, "?");
  }

  return null;
};

module.exports = function FileInfo(props) {
  return h("div", {
    className: "uppy-Dashboard-Item-fileInfo",
    "data-uppy-file-source": props.file.source
  }, renderFileName(props), h("div", {
    className: "uppy-Dashboard-Item-status"
  }, renderFileSize(props), ReSelectButton(props), h(ErrorButton, {
    file: props.file // eslint-disable-next-line no-alert
    ,
    onClick: () => alert(props.file.error) // TODO: move to a custom alert implementation

  })));
};