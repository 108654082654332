import * as React from 'react'

import { LANG } from '../i18n'

interface Props {
  lang: LANG
}

export default class Header extends React.Component<Props> {
  render() {
    return <header className="d-flex flex-column">
      <div className="p-4">{/* compensate navbar*/}</div>
      <div className="d-flex flex-column text-center my-auto">
        <h2 className="mt-4">
          20th July 2019
        </h2>

        <h1 className="my-5">
          Hippolyte<br />
          &amp;<br />
          Isis
        </h1>

        <h2 className="mb-4">
          Altamount Manor<br />
          Blairgowrie, Perthshire, Scotland
        </h2>
      </div>
    </header>
  }
}
