import { padStart } from 'lodash' // https://caniuse.com/#search=padStart

// TODO hpello use a better random generator
function makeRandomInt(size: number) {
  return Math.floor(Math.random() * size) % size
}

// seconds: 32 bits (until at least 2106)
// millis: 10 bits
// random: 22 bits
// total: 64 bits
export const utcRandomID = (): string => {
  const millis = Date.now()
  const numbers = [
    Math.floor(millis / 1000), // 32 bits
    (millis - 1000 * Math.floor(millis / 1000)) * Math.pow(2, 22) + makeRandomInt(Math.pow(2, 22)) // 10 + 22 = 32 bits
  ] // 2 * 32 bits = 64 bits

  return numbers
    .map(x => padStart(x.toString(16), 8, '0'))
    .join('')
}
