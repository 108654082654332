import * as React from 'react'

import { LANG, t } from '../i18n'
import { SECTIONS } from '../constants'

interface Props {
  lang: LANG
}

export default class Holiday extends React.Component<Props> {
  render() {
    const { lang } = this.props
    const section = SECTIONS.holiday

    return <section id={section} className={`${section}-section text-justify`}>
      <h2 className="text-center">{t.sections[section](lang)}</h2>

      <div className="container my-4">
        <div className="row">
          <div className="col-md">
            {t.holidayintro(lang)}
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 p-2 mx-auto">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/FlG6tbYaA88" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            {t.holidaycontents(lang)}
          </div>
        </div>
      </div>
    </section>
  }
}
