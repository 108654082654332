import * as React from 'react'

import ImageFigure from './components/ImageFigure'

export enum LANG {
  fr = 'fr',
  es = 'es',
  en = 'en'
}

const { en, fr, es } = LANG

const full = (str: string) => <span className="text-danger">{str.toUpperCase()}</span>
const link = (href: string, str?: string) => <a target="_blank" href={href}>{str ? str : <i className="fa fa-fw fa-external-link" />}</a>
const row = (time: string, desc: string) => <div className="row">
  <div className="col-sm-3 text-sm-left"><strong>{time}</strong></div>
  <div className="col-sm-9 text-sm-left">{desc}</div>
</div>

const pick = (translations: { [key in LANG]: React.ReactNode } & { [en]: React.ReactNode }) => (l: LANG) =>
  l in translations ? translations[l] : translations[en]

export const t = {
  sections: {
    wedding: pick({
      [en]: 'Wedding',
      [fr]: 'Mariage',
      [es]: 'Boda'
    }),
    venue: pick({
      [en]: 'Venue',
      [fr]: 'Lieu',
      [es]: 'Lugar de celebraciÃ³n'
    }),
    holiday: pick({
      [en]: 'Extend your trip',
      [fr]: 'Prolongez votre sÃ©jour',
      [es]: 'Prolonga tu estancia'
    }),
    rsvp: pick({
      [en]: 'RSVP',
      [fr]: 'RSVP',
      [es]: 'RSVP'
    }),
    giftList: pick({
      [en]: 'Wedding List',
      [fr]: 'Liste de mariage',
      [es]: 'Lista de boda'
    }),
    upload: pick({
      [en]: 'Share your photos & videos',
      [fr]: 'Partagez vos photos & vidÃ©os',
      [es]: 'Comparte tus fotos y vÃ­deos'
    })
  },

  // wedding
  where: pick({
    [en]: 'Where?',
    [fr]: 'OÃ¹ ?',
    [es]: 'Â¿DÃ³nde?'
  }),
  when: pick({
    [en]: 'When?',
    [fr]: 'Quand ?',
    [es]: 'Â¿CuÃ¡ndo?'
  }),
  contact: pick({
    [en]: 'Contact',
    [fr]: 'Contact',
    [es]: 'Contacto'
  }),
  contacttext: pick({
    [en]: 'If you have any question, or if you would like to participate during the wedding day (speech, sketchâ¦), please get in touch with our witnesses.',
    [fr]: 'Si vous avez besoin d\'informations ou si vous souhaitez prÃ©parer une animation ou un discours, vous pouvez entrer en contact avec nos tÃ©moins.',
    [es]: 'Si tenÃ©is alguna duda o si querÃ©is organizar alguna animaciÃ³n o discurso el dÃ­a de la boda, podÃ©is poneros en contacto con nuestros testigos.'
  }),
  datefri: pick({
    [en]: 'Fri. July 19th',
    [fr]: 'Ven. 19 juillet',
    [es]: 'Vi. 19 de julio'
  }),
  datesat: pick({
    [en]: 'Sat. July 20th',
    [fr]: 'Sam. 20 juillet',
    [es]: 'SÃ¡. 20 de julio'
  }),
  datesun: pick({
    [en]: 'Sun. July 21st',
    [fr]: 'Dim. 21 juillet',
    [es]: 'Do. 21 de julio'
  }),
  eveningsnack: pick({
    [en]: row('18:00', 'Welcome dinner'),
    [fr]: row('18:00', 'DÃ®ner d\'accueil'),
    [es]: row('18:00', 'Cena de bienvenida')
  }),
  brunch: pick({
    [en]: row('11:00', 'Brunch'),
    [fr]: row('11:00', 'Brunch'),
    [es]: row('11:00', 'Brunch')
  }),
  wedding: pick({
    [en]: <>
      {row('13:30', 'Guests welcome')}
      {row('14:00', 'Ceremony')}
      {row('15:00', 'Drinks reception & Highland Games')}
      {row('17:30', 'Wedding breakfast')}
      {row('20:00', 'Disco fever')}
      {row('01:30', 'End')}
    </>,
    [fr]: <>
      {row('13:30', 'Accueil des invitÃ©s')}
      {row('14:00', 'CÃ©rÃ©monie')}
      {row('15:00', 'Vin d\'honneur & Highland Games')}
      {row('17:30', 'DÃ®ner')}
      {row('20:00', 'SoirÃ©e dansante')}
      {row('01:30', 'Fin des festivitÃ©s')}
    </>,
    [es]: <>
      {row('13:30', 'Llegada de invitados')}
      {row('14:00', 'Ceremonia')}
      {row('15:00', 'CÃ³ctel de bienvenida & Highland Games')}
      {row('17:30', 'Cena')}
      {row('20:00', 'Baile')}
      {row('01:30', 'Fin de fiesta')}
    </>
  }),
  programcomments: pick({
    [en]: 'All events will take place at the Altamount Manor.',
    [fr]: 'L\'ensemble des Ã©vÃ©nements aura lieu Ã  l\'Altamount Manor.',
    [es]: 'Todos los eventos tendrÃ¡n lugar en Altamount Manor.'
  }),

  // venue
  howtogetthere: pick({
    [en]: 'How to get there?',
    [fr]: 'Comment y aller ?',
    [es]: 'Â¿CÃ³mo llegar?'
  }),
  wheretostay: pick({
    [en]: 'Where to stay?',
    [fr]: 'OÃ¹ loger ?',
    [es]: 'Alojamiento'
  }),
  howtogettherecontents: pick({
    [en]: <>
      <p>
        The simplest way to reach Scotland is to get a flight to either Edinburgh or Glasgow.
        <br />
        <br />
        From there, you can get to Blairgowrie either by car or public transport.
        We personally advice to rent a car for practical reasons (why not sharing a car with other guests?).
        Little tip: rent your car <strong>at the airport</strong> to avoid some traffic, especially for those not used to driving on the left!
        <br />
        <br />
        Do not hesitate to contact us if you need some help organising your journey.
      </p>
      <ul>
        <li>
          <i className="fa fa-fw fa-plane" /> Edinburgh
          <ul>
            <li><i className="fa fa-fw fa-car" /> 1:10h from the airport to Blairgowrie</li>
            <li><i className="fa fa-fw fa-train" /> <i className="fa fa-fw fa-bus" /> Around 2:45h by train + bus (via Perth or Dundee)</li>
          </ul>
        </li>
        <li>
          <i className="fa fa-fw fa-plane" /> Glasgow
          <ul>
            <li><i className="fa fa-fw fa-car" /> 1:30h from the airport to Blairgowrie</li>
            <li><i className="fa fa-fw fa-train" /> <i className="fa fa-fw fa-bus" /> Around 2:45h by train + bus (via Perth or Dundee)</li>
          </ul>
        </li>
      </ul>
    </>,
    [fr]: <>
      <p>
        Pour aller en Ãcosse, le plus simple est de prendre l'avion jusqu'Ã  Ãdimbourg ou Glasgow.
        <br />
        <br />
        Puis, pour vous rendre Ã  Blairgowrie, vous pouvez soit louer une voiture, soit opter pour les transports en commun.
        Nous vous recommandons la location de voiture (vous pouvez vous associer Ã  d'autres invitÃ©s pour organiser des covoiturages).
        PrÃ©fÃ©rez la location <strong>depuis l'aÃ©roport</strong>, le trajet sera plus court et le traffic moins dense !
        <br />
        <br />
        N'hÃ©sitez pas Ã  nous contacter si vous avez besoin d'aide pour organiser votre trajet.
      </p>
      <ul>
        <li>
          <i className="fa fa-fw fa-plane" /> Ãdimbourg
          <ul>
            <li><i className="fa fa-fw fa-car" /> 1h10 depuis l'aÃ©roport jusqu'Ã  Blairgowrie</li>
            <li><i className="fa fa-fw fa-train" /> <i className="fa fa-fw fa-bus" /> Environ 2h45 de trajet en train + bus via Perth ou Dundee</li>
          </ul>
        </li>
        <li>
          <i className="fa fa-fw fa-plane" /> Glasgow
          <ul>
            <li><i className="fa fa-fw fa-car" /> 1h30 depuis l'aÃ©roport jusqu'Ã  Blairgowrie</li>
            <li><i className="fa fa-fw fa-train" /> <i className="fa fa-fw fa-bus" /> Environ 2h45 de trajet en train + bus via Perth ou Dundee</li>
          </ul>
        </li>
      </ul>
    </>,
    [es]: <>
      <p>
        Para llegar a Escocia, lo mÃ¡s sencillo es coger un vuelo a Edimburgo o Glasgow.
        <br />
        <br />
        Es posible llegar a Blairgowrie tanto en coche como en transporte pÃºblico.
        Personalmente, recomendamos alquilar un coche, ya que es bastante mÃ¡s prÃ¡ctico y rÃ¡pido. TambiÃ©n, existe la posibilidad de ponerse de acuerdo con otros invitados para compartir coche.
        Recomendamos alquilar los coches <strong>en el aeropuerto</strong>, asÃ­, el trayecto serÃ¡ mÃ¡s corto y habrÃ¡ menos trÃ¡fico (mÃ¡s fÃ¡cil para empezar, Â¡sobre todo, teniendo en cuenta que se conduce por la izquierda!).
        <br />
        <br />
        No dudÃ©is en poneros en contacto con nosotros si necesitÃ¡is ayuda para organizar vuestro viaje.
      </p>
      <ul>
        <li>
          <i className="fa fa-fw fa-plane" /> Edimburgo
          <ul>
            <li><i className="fa fa-fw fa-car" /> Aproximadamente 1:10Â h desde el aeropuerto hasta Blairgowrie</li>
            <li><i className="fa fa-fw fa-train" /> <i className="fa fa-fw fa-bus" /> Desde el centro de Edimburgo, aproximadamente 2:45Â h de trayecto en tren (a Dundee o Perth) + autobÃºs </li>
          </ul>
        </li>
        <li>
          <i className="fa fa-fw fa-plane" /> Glasgow
          <ul>
            <li><i className="fa fa-fw fa-car" /> Aproximadamente 1:30Â h desde el aeropuerto hasta Blairgowrie</li>
            <li><i className="fa fa-fw fa-train" /> <i className="fa fa-fw fa-bus" /> Desde el centro de Glasgow, aproximadamente 2:45Â h de trayecto en tren (a Dundee o Perth) + autobÃºs </li>
          </ul>
        </li>
      </ul>
    </>
  }),
  wheretostaycontents: pick({
    [en]: <>
      <h4>Blairgowrie</h4>

      <div className="alert alert-warning">
        <i className="fa fa-fw fa-warning" />{' '}
        Due to other events taking place on the same weekend, little accomodation options are still available in Blairgowrie.
      </div>

      <ul>
        <li>
            Next to the manor:
            <ul>
                <li>Altamount Park {link('http://www.altamountpark.co.uk/')} {full('no vacancy')}</li>
                <li>Altamount Chalets {link('http://www.altamount-chalets.co.uk/')} {full('no vacancy')}</li>
            </ul>
        </li>
        <li>
            In the town centre (5-10 minutes walking distance from the manor):
            <ul>
                <li>The Angus Hotel {link('http://www.theangushotel.com/')} {full('no vacancy')}</li>
                <li>Victoria Hotel {full('no vacancy')}</li>
                <li>Ivybank Lodge {link('http://ivybanklodge.com/')} {full('no vacancy')}</li>
            </ul>
        </li>
        <li>
            Some B&Bs in the area (5-10 minutes walking distance from the manor):
            <ul>
                <li>Eildon Bank B&B {link('http://www.eildonbank.co.uk/')}</li>
                <li>Gilmore House B&B</li>
                <li>Garfield House B&B</li>
            </ul>
        </li>
        <li>
            Other options available on AirBnB, Booking etc. Don't hesitate to contact us for further info.
        </li>
      </ul>

      <h4>Dundee <small>(30 minutes from the Altamount Manor by car)</small></h4>

      <div className="alert alert-info">
        <i className="fa fa-fw fa-info-circle" />{' '}
        If you stay in Dundee, please let us know on the RSVP form.
        <br />
        Depending on the number of guests staying in the area, we might organise a shuttle to/from the manor.
      </div>

      <ul>
        <li>
            Suggestions:
            <ul>
                <li>Best Western Dundee Invercarse Hotel {link('https://www.bestwestern.fr/en/hotel-Dundee-Best-Western-Invercarse-Hotel-83440')}</li>
                <li>Best Western Queens Hotel {link('https://www.bestwestern.co.uk/booking/guest-details/best-western-queens-hotel-83495')}</li>
            </ul>
        </li>
        <li>
            Other options available on AirBnB, Booking etc. Don't hesitate to contact us for further info.
        </li>
      </ul>

      <h4>Perth <small>(30 minutes from the Altamount Manor by car)</small></h4>
    </>,
    [fr]: <>
      <h4>Blairgowrie</h4>

      <div className="alert alert-warning">
        <i className="fa fa-fw fa-warning" />{' '}
        Ã cause d'autres Ã©vÃ©nements ayant lieu dans la ville pendant le week-end du mariage, peu de logements sont encore disponibles Ã  Blairgowrie.
      </div>

      <ul>
        <li>
            Ã cÃ´tÃ© du manoir :
            <ul>
                <li>Altamount Park {link('http://www.altamountpark.co.uk/')} {full('complet')}</li>
                <li>Altamount Chalets {link('http://www.altamount-chalets.co.uk/')} {full('complet')}</li>
            </ul>
        </li>
        <li>
            Dans le centre-ville (5-10 minutes Ã  pied du manoir) :
            <ul>
                <li>The Angus Hotel {link('http://www.theangushotel.com/')} {full('complet')}</li>
                <li>Victoria Hotel {full('complet')}</li>
                <li>Ivybank Lodge {link('http://ivybanklodge.com/')} {full('complet')}</li>
            </ul>
        </li>
        <li>
            Plusieurs B&Bs Ã  proximitÃ© (5-10 minutes Ã  pied du manoir) :
            <ul>
                <li>Eildon Bank B&B {link('http://www.eildonbank.co.uk/')}</li>
                <li>Gilmore House B&B</li>
                <li>Garfield House B&B</li>
            </ul>
        </li>
        <li>
            D'autres logements disponibles sur AirBnB, Booking etc. N'hÃ©sitez pas Ã  nous contacter si besoin.
        </li>
      </ul>

      <h4>Dundee <small>(Ã  30 min d'Altamount Manor en voiture)</small></h4>

      <div className="alert alert-info">
        <i className="fa fa-fw fa-info-circle" />{' '}
        Si vous logez Ã  Dundee, merci de nous en informer grÃ¢ce au formulaire RSVP.
        <br />
        En effet, selon le nombre de personnes nous essayerons d'organiser des navettes vers et depuis le lieu du mariage.
      </div>

      <ul>
        <li>
            Suggestions :
            <ul>
                <li>Best Western Dundee Invercarse Hotel {link('https://www.bestwestern.fr/en/hotel-Dundee-Best-Western-Invercarse-Hotel-83440')}</li>
                <li>Best Western Queens Hotel {link('https://www.bestwestern.co.uk/booking/guest-details/best-western-queens-hotel-83495')}</li>
            </ul>
        </li>
        <li>
            D'autres logements disponibles sur AirBnB, Booking etc. N'hÃ©sitez pas Ã  nous contacter si besoin.
        </li>
      </ul>

      <h4>Perth <small>(Ã  30 min d'Altamount Manor en voiture)</small></h4>
    </>,
    [es]: <>
      <h4>Blairgowrie</h4>

      <div className="alert alert-warning">
        <i className="fa fa-fw fa-warning" />{' '}
        Debido a otros eventos que se celebran en Blairgowrie el fin de semana de nuestra boda, quedan pocas opciones de alojamiento disponibles cerca del lugar de celebraciÃ³n, Â¡reservad lo antes posible!
      </div>

      <ul>
        <li>
            Al lado de la mansiÃ³n:
            <ul>
                <li>Altamount Park {link('http://www.altamountpark.co.uk/')} {full('completo')}</li>
                <li>Altamount Chalets {link('http://www.altamount-chalets.co.uk/')} {full('completo')}</li>
            </ul>
        </li>
        <li>
            En el centro de Blairgowrie (a 5-10 minutos a pie de la mansiÃ³n):
            <ul>
                <li>The Angus Hotel {link('http://www.theangushotel.com/')} {full('completo')}</li>
                <li>Victoria Hotel {full('completo')}</li>
                <li>Ivybank Lodge {link('http://ivybanklodge.com/')} {full('completo')}</li>
            </ul>
        </li>
        <li>
            Varios B&B en los alrededores (a 5-10 minutos a pie de la mansiÃ³n):
            <ul>
                <li>Eildon Bank B&B {link('http://www.eildonbank.co.uk/')}</li>
                <li>Gilmore House B&B</li>
                <li>Garfield House B&B</li>
            </ul>
        </li>
        <li>
            Otras opciones disponibles en AirBnB, Booking, etc. No dudÃ©is en poneros en contacto con nosotros si necesitÃ¡is ayuda.
        </li>
      </ul>

      <h4>Dundee <small>(a 30 minutos en coche de Altamount Manor)</small></h4>

      <div className="alert alert-info">
        <i className="fa fa-fw fa-info-circle" />{' '}
        Si reservÃ¡is un alojamiento en Dundee, avisadnos en el formulario de la secciÃ³n 'RSVP'.
        <br />
        SegÃºn el nÃºmero de personas que se alojen en Dundee, intentaremos organizar un minibÃºs hacia/desde la mansiÃ³n el dÃ­a de la boda.
      </div>

      <ul>
        <li>
            Sugerencias:
            <ul>
                <li>Best Western Dundee Invercarse Hotel {link('https://www.bestwestern.fr/en/hotel-Dundee-Best-Western-Invercarse-Hotel-83440')}</li>
                <li>Best Western Queens Hotel {link('https://www.bestwestern.co.uk/booking/guest-details/best-western-queens-hotel-83495')}</li>
            </ul>
        </li>
        <li>
            Otras opciones disponibles en AirBnB, Booking, etc. No dudÃ©is en poneros en contacto con nosotros si necesitÃ¡is ayuda.
        </li>
      </ul>

      <h4>Perth <small>(a 30 minutos en coche de Altamount Manor)</small></h4>
    </>
  }),

  // rsvp
  yes: pick({
    [en]: 'Yes',
    [fr]: 'Oui',
    [es]: 'SÃ­'
  }),
  no: pick({
    [en]: 'No',
    [fr]: 'Non',
    [es]: 'No'
  }),
  other: pick({
    [en]: 'Other',
    [fr]: 'Autre',
    [es]: 'InformaciÃ³n adicional'
  }),
  othertellusbelow: pick({
    [en]: 'Other (let us know below)',
    [fr]: 'Autre (prÃ©ciser ci-dessous)',
    [es]: 'Otro (indicar en comentarios)'
  }),
  babys: pick({
    [en]: 'Coming with baby(s)',
    [fr]: 'Je viens avec bÃ©bÃ©(s)',
    [es]: 'Vengo con mi(s) bebÃ©(s)'
  }),
  numberofpeople: pick({
    [en]: 'Number of people',
    [fr]: 'Nombre de personnes',
    [es]: 'NÃºmero de personas'
  }),
  attendance: pick({
    [en]: 'Are you coming?',
    [fr]: 'PrÃ©sence',
    [es]: 'Confirmar asistencia'
  }),
  guestnames: pick({
    [en]: 'Guest names',
    [fr]: 'Noms des invitÃ©s',
    [es]: 'Nombre de los invitados'
  }),
  guestnumber: pick({
    [en]: 'Guest nÂ°',
    [fr]: 'InvitÃ© nÂ°',
    [es]: 'Invitado nÂ°'
  }),
  yesimcoming: pick({
    [en]: 'Yes, I\'m coming!',
    [fr]: 'Oui, je viens avec plaisir !',
    [es]: 'Â¡SÃ­, allÃ­ estarÃ©!'
  }),
  noimnotcoming: pick({
    [en]: 'No, I can\'t make it.',
    [fr]: 'Non, je ne pourrai pas venir.',
    [es]: 'Lo siento, no podrÃ© venir.'
  }),
  meals: pick({
    [en]: 'Other meals',
    [fr]: 'Repas',
    [es]: 'Otras comidas'
  }),
  fridaysnack: pick({
    [en]: 'Friday evening snack',
    [fr]: 'Snack du vendredi soir',
    [es]: 'Cena del viernes por la noche'
  }),
  sundaybrunch: pick({
    [en]: 'Sunday brunch',
    [fr]: 'Brunch du dimanche matin',
    [es]: 'Brunch del domingo'
  }),
  accomodation: pick({
    [en]: 'Accomodation',
    [fr]: 'Logement',
    [es]: 'Alojamiento'
  }),
  whereareyoustaying: pick({
    [en]: 'Where are you staying?',
    [fr]: 'OÃ¹ logerez-vous ?',
    [es]: 'Â¿DÃ³nde te alojas?'
  }),
  comments: pick({
    [en]: 'Comments',
    [fr]: 'Commentaires',
    [es]: 'Comentarios'
  }),
  commentsinfo: pick({
    [en]: '(questions, accomodation address, special dietary requirementsâ¦)',
    [fr]: '(questions, adresse de votre logement, restrictions alimentairesâ¦)',
    [es]: '(preguntas, direcciÃ³n del alojamiento, restricciones alimentariasâ¦)'
  }),
  message: pick({
    [en]: 'Message',
    [fr]: 'Message',
    [es]: 'Mensaje'
  }),
  send: pick({
    [en]: 'Send',
    [fr]: 'Envoyer',
    [es]: 'Enviar'
  }),
  sending: pick({
    [en]: 'Sending',
    [fr]: 'Envoi en cours',
    [es]: 'Enviando'
  }),
  thankyou: pick({
    [en]: 'Thank you!',
    [fr]: 'Merci !',
    [es]: 'Â¡Gracias!'
  }),

  // holiday
  holidayintro: pick({
    [en]: <>
      <p>
        Scotland is a wonderful country with a lot of hidden treasuresâ¦ This can be the perfect opportunity to extend your stay!
      </p>
      </>,
    [fr]: <p>
      L'Ãcosse est un pays magnifique avec plein de trÃ©sors Ã  dÃ©couvrirâ¦ Cela peut Ãªtre l'occasion d'en profiter pour y prolonger votre sÃ©jour !
    </p>,
    [es]: <>
      <p>
        Escocia es un paÃ­s maravilloso con numerosos tesoros por descubrirâ¦ Â¡Es la ocasiÃ³n perfecta para alargar vuestra estancia unos dÃ­as!
      </p>
    </>
  }),
  holidaycontents: pick({
    [en]: <>
      <p>You can find below some ideas, suggestions and tips to prepare your trip.</p>
      <ul>
        <li>
          <h5>{link('https://www.visitscotland.com/info/towns-villages/blairgowrie-p241891', 'Blairgowrie')}</h5>
          <p>Discover this lovely town, the perfect point of departure for a trip to the Highlands!</p>
        </li>

        <li>
          <h5>{link('https://www.visitscotland.com/see-do/itineraries/', 'Itinerary suggestions')}</h5>
          <p>Some ideas to plan your trip to Scotland according to your needs and the length of your stay (Scotland's Castle Trail, Whisky Trail, Film & TV Locations Itineraryâ¦).</p>
        </li>

        <li>
          <h5>{link('https://www.visitscotland.com/destinations-maps/highlands/', 'The Highlands')}</h5>
          <p>Discover this magical region, with a special atmosphere and stunning landscapes.</p>
        </li>

        <li>
          <h5>{link('https://www.visitscotland.com/see-do/attractions/tv-film/harry-potter-itinerary/', 'For Harry Potter fans')}</h5>
          <p>Did you know that Harry Potter was born at <em>The Elephant House</em> cafÃ© in Edinburgh? Discover some of the places that inspired JK Rowling and some of the amazing locations of the films.</p>
          <div className="text-center">
            <ImageFigure
              src="../../img/harry-potter-train.jpg"
              alt="Train Harry Potter"
              caption="The Hogwarts Express crossing the Glenfinnan Viaduct"
            />
          </div>
        </li>

        <li>
          <h5>Other interesting links</h5>
          <ul>
            <li>
              {link('https://wanderlustcrew.com/scotland-itinerary/', 'Perfect Scotland Itinerary')}
              <p>Tips to prepare your trip and a detailed seven day itinerary.</p>
            </li>
            <li>
              {link('https://www.earthtrekkers.com/10-day-scotland-itinerary/', '10 day Scotland Itinerary')}
              <p>Visiting Edinburgh, Glasgow & the Isle of Skye.</p>
            </li>
            <li>
              {link('https://www.edinburghfestivalcity.com/', 'Edinburgh\'s Festivals')}
              <p>If you are lucky enough to extend your trip until August, don't miss the incredible festivals that take over the city of Edinburghâ¦ A must!</p>
            </li>
          </ul>
          <br />
          <br />
          <div className="text-center">
            <ImageFigure
              src="../../img/highlands-crew.jpg"
              alt="Road trip to the Highlands in 2011"
              caption="Road trip to the Highlands in 2011"
            />
          </div>
        </li>
      </ul>
    </>,
    [fr]: <>
      <p>Voici quelques idÃ©es.</p>
      <ul>
        <li>
          <h5>{link('https://www.visitscotland.com/fr-fr/info/towns-villages/blairgowrie-p241891', 'Blairgowrie')}</h5>
          <p>Profitez de ce petit village, point de dÃ©part des Highlands.</p>
        </li>

        <li>
          <h5>{link('https://www.visitscotland.com/fr-fr/see-do/itineraries/', 'IdÃ©es d\'itinÃ©raires')}</h5>
          <p>Planifiez des itinÃ©raires adaptÃ©s Ã  vos envies et la durÃ©e de votre sÃ©jour (sentier des chÃ¢teaux d'Ãcosse, circuit du malt, lieux de tournagesâ¦).</p>
        </li>

        <li>
          <h5>{link('https://www.visitscotland.com/fr-fr/destinations-maps/highlands/', 'Highlands')}</h5>
          <p>DÃ©couvrez cette rÃ©gion magique Ã  l'atmosphÃ¨re et aux paysages uniques.</p>
        </li>

        <li>
          <h5>{link('https://www.visitscotland.com/fr-fr/see-do/attractions/tv-film/harry-potter-itinerary/', 'Harry Potter')}</h5>
          <p>Saviez-vous qu'Harry Potter Ã©tait nÃ© Ã  l'<em>Elephant House</em> Ã  Ãdimbourg ? C'est dans ce cafÃ© que J.K. Rowling a Ã©crit les premiers romans du cÃ©lÃ¨bre sorcier. De plus, beaucoup de passages des films ont Ã©tÃ© tournÃ©s en Ãcosse.</p>
          <div className="text-center">
            <ImageFigure
              src="../../img/harry-potter-train.jpg"
              alt="Train Harry Potter"
              caption="Le Poudlard Express traversant le viaduc de Glenfinnan"
            />
          </div>
        </li>

        <li>
          <h5>Autres liens intÃ©ressants</h5>
          <ul>
            <li>
              {link('https://wanderlustcrew.com/scotland-itinerary/', 'Perfect Scotland Itinerary (EN)')}
              <p>Trucs et astuces pour prÃ©parer votre sÃ©jour.</p>
            </li>
            <li>
              {link('https://www.earthtrekkers.com/10-day-scotland-itinerary/', '10 day Scotland Itinerary (EN)')}
              <p>Ãdimbourg, Glasgow & Ãle de Skye.</p>
            </li>
            <li>
              {link('https://travel.oohmyworld.com/festival-edimbourg-mode-demploi/', 'Le festival d\'Ãdimbourg, mode d\'emploi')}
              <p>Le plus gros Ã©vÃ©nement culturel au monde, c'est au mois d'aoÃ»t Ã  Ãdimbourg !</p>
            </li>
          </ul>
          <br />
          <br />
          <div className="text-center">
            <ImageFigure
              src="../../img/highlands-crew.jpg"
              alt="Road trip dans les Highlands en 2011"
              caption="Road trip dans les Highlands en 2011"
            />
          </div>
        </li>
      </ul>
    </>,
    [es]: <>
      <p>Os dejamos algunas ideas, consejos y sugerencias.</p>
      <ul>
        <li>
          <h5>{link('https://www.visitscotland.com/es-es/info/towns-villages/blairgowrie-p241891', 'Blairgowrie y sus alrededores')}</h5>
          <p>Blairgowrie es una ciudad con mucho encanto y el punto de partida perfecto para descubrir las Highlands.</p>
        </li>

        <li>
          <h5>{link('https://www.visitscotland.com/es-es/see-do/itineraries/', 'Ideas de itinerarios')}</h5>
          <p>Ideas de itinerarios variados segÃºn vuestros intereses y adaptados a la duraciÃ³n de vuestra estancia (ruta de los castillos escoceses, ruta del whisky, lugares de rodajes en Escociaâ¦).</p>
        </li>

        <li>
          <h5>{link('https://www.visitscotland.com/es-es/destinations-maps/highlands/', 'Las Highlands')}</h5>
          <p>Una regiÃ³n mÃ¡gica con una atmÃ³sfera Ãºnica y paisajes inolvidables.</p>
        </li>

        <li>
          <h5>{link('https://www.visitscotland.com/es-es/see-do/attractions/tv-film/harry-potter-itinerary/', 'Para los entusiastas de Harry Potter')}</h5>
          <p>Â¿SabÃ­ais que Harry Potter tiene sus orÃ­genes en la cafeterÃ­a <em>The Elephant House</em> de Edimburgo? Escocia esconde nÃºmerosos rincones que inspiraron a J. K. Rowling o sirvieron de escenario para las pelÃ­culas del mago mÃ¡s famoso.</p>
          <div className="text-center">
            <ImageFigure
              src="../../img/harry-potter-train.jpg"
              alt="Tren Harry Potter"
              caption="El Hogwarts Express cruzando el viaducto de Glenfinnan"
            />
          </div>
        </li>

        <li>
          <h5>Otros enlaces interesantes</h5>
          <ul>
            <li>
              {link('https://wanderlustcrew.com/scotland-itinerary/', 'Perfect Scotland Itinerary (EN)')}
              <p>Blog con consejos para preparar vuestro viaje por Escocia y un itinerario de siete dÃ­as para inspiraros.</p>
            </li>
            <li>
              {link('https://www.viajeroscallejeros.com/10-lugares-que-visitar-escocia/', '10 lugares que visitar en Escocia imprescindibles')}
              <p>Blog con algunos de los lugares mÃ¡s emblemÃ¡ticos de Escocia.</p>
            </li>
            <li>
              {link('https://masedimburgo.com/guias-de-edimburgo/el-festival/', 'GuÃ­a del Festival de Edimburgo')}
              <p>El Festival de Edimburgo se celebra cada aÃ±o en agosto y reÃºne una multitud de festivales y eventos culturales. Si tenÃ©is la oportunidad de alargar vuestra estancia, Â¡no os lo perdÃ¡is!</p>
            </li>
            <li>
              {link('https://viajarporescocia.com/', 'Excursiones y tours en espaÃ±ol')}
              <p>Si preferÃ­s las excursiones y viajes organizados, esta empresa ofrece tours y excursiones en espaÃ±ol.</p>
            </li>
          </ul>
          <br />
          <br />
          <div className="text-center">
            <ImageFigure
              src="../../img/highlands-crew.jpg"
              alt="Viaje por las Highlands en 2011"
              caption="Viaje por las Highlands en 2011"
            />
          </div>
        </li>
      </ul>
    </>
  }),

  // gift list
  giftlistcontents: pick({
    [en]: <>
      <p>
        The best present we might receive is to share such a special day in Scotland with all of you. Nevertheless, some of you have asked us about the wedding list. As we have been living together for several years now, we are well settled in our place. We are planning, however, to spend our honeymoon in Japan, so you can contribute to this project if you wish.
      </p>
      <br />
      <br />
      <div className="text-center">
        <ImageFigure
          src="../../img/fuji-sakura.jpg"
          alt="Mount Fuji and Sakura"
          caption="Mount Fuji and Sakura"
        />
      </div>
      </>,
    [fr]: <>
      <p>
        Bien installÃ©s dans notre petit chez nous depuis quelques temps, nous n'avons pas de besoin spÃ©cifique pour notre quotidien, et serons dÃ©jÃ  touchÃ©s par votre prÃ©sence Ã  notre mariage. Si malgrÃ© tout vous souhaitez nous offrir quelque chose, nous prÃ©voyons pour l'annÃ©e prochaine un voyage de noces au Japon, auquel vous pouvez librement contribuer.
      </p>
      <br />
      <br />
      <div className="text-center">
        <ImageFigure
          src="../../img/fuji-sakura.jpg"
          alt="Mont Fuji et Sakura"
          caption="Mont Fuji et Sakura"
        />
      </div>
    </>,
    [es]: <>
      <p>
        El mejor regalo que nos podÃ©is hacer es compartir con nosotros este dÃ­a tan especial en Escocia. Sin embargo, algunos de vosotros nos habÃ©is preguntado sobre la lista de boda. Como ya llevamos unos aÃ±os instalados en nuestro piso, no tenemos ninguna necesidad particular para el dÃ­a a dÃ­a, pero hemos previsto pasar nuestra luna de miel en JapÃ³n, asÃ­ que, si lo deseÃ¡is, podÃ©is participar en este proyecto.
      </p>
      <br />
      <br />
      <div className="text-center">
        <ImageFigure
          src="../../img/fuji-sakura.jpg"
          alt="Monte Fuji y Sakura"
          caption="Monte Fuji y Sakura"
        />
      </div>
    </>
  }),
  uploadcontents: pick({
    [en]: 'We would love to see the photos and videos of your favorite moments of the wedding. Share them with us!',
    [fr]: 'Nous serions ravis de visionner les photos et vidÃ©os de vos moments prÃ©fÃ©rÃ©s du mariage. Partagez-les avec nous !',
    [es]: 'Nos encantarÃ­a ver las fotos y vÃ­deos de tus momentos favoritos de nuestra boda. Â¡CompÃ¡rtelos con nosotros!'
  }),
  name: pick({
    [en]: 'Name',
    [fr]: 'Nom',
    [es]: 'Nombre'
  }),
  namePlaceholder: pick({
    [en]: 'Enter your name',
    [fr]: 'Entrez votre nom',
    [es]: 'Introduce tu nombre'
  }),
  password: pick({
    [en]: 'Password',
    [fr]: 'Mot de passe',
    [es]: 'ContraseÃ±a'
  }),
  helloname: (username: string) => pick({
    [en]: `Hello, ${username}!`,
    [fr]: `Bonjour, ${username} !`,
    [es]: `Â¡Hola, ${username}!`
  })
}

const LANG_KEY = 'lang'

export const setLang = (lang: LANG) => localStorage.setItem(LANG_KEY, lang)

export const getLang = () => {
  switch (localStorage.getItem(LANG_KEY)) {
    case en: return en
    case fr: return fr
    case es: return es
  }
  return fr
}
