import * as React from 'react'

import { LANG, t } from '../i18n'
import { SECTIONS } from '../constants'

import ImageFigure from './ImageFigure'

interface Props {
  lang: LANG
}

export default class Venue extends React.Component<Props> {
  render() {
    const { lang } = this.props
    const section = SECTIONS.venue

    return <section id={section} className={`${section}-section text-justify invert-colors`}>
      <h2 className="text-center">{t.sections[section](lang)}</h2>

      <div className="container">
        <div className="row">
          <div className="col text-center">
            <ImageFigure
              src="../../img/altamount-manor.jpg"
              alt="Altamount Manor"
              caption="Altamount Manor"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md my-4">
            <h3>{t.howtogetthere(lang)}</h3>

            {t.howtogettherecontents(lang)}
          </div>
        </div>

        <div className="row">
          <div className="col-md my-4">
            <h3>{t.wheretostay(lang)}</h3>

            {t.wheretostaycontents(lang)}
          </div>
        </div>
      </div>
    </section>
  }
}
