import * as React from 'react'

import { LANG, t } from '../i18n'
import { SECTIONS } from '../constants'
import { SUBMIT_STATUS, submitForm } from '../submit'

enum BOOLEAN {
  true = 'true',
  false = 'false'
}

enum AREA {
  blairgowrie = 'blairgowrie',
  perth = 'perth',
  dundee = 'dundee',
  other = 'other'
}

interface Props {
  lang: LANG
}

interface FormState {
  numGuests: number
  guests: string[]
  coming: boolean | null
  baby: boolean
  friday: boolean | null
  sunday: boolean | null
  location: AREA | string | null
  message: string
}

interface SubmitState {
  sending: boolean
  status: SUBMIT_STATUS | null
}

interface State {
  form: FormState,
  submit: SubmitState
}

export default class RSVP extends React.Component<Props, State> {
  state: State = {
    form: {
      numGuests: 1,
      guests: [''],
      coming: null,
      baby: false,
      friday: null,
      sunday: null,
      location: null,
      message: ''
    },
    submit: {
      sending: false,
      status: null
    }
  }

  setFormState(form: Partial<FormState>) {
    this.setState({ form: { ...this.state.form, ...form } })
  }

  setSubmitState(submit: Partial<SubmitState>) {
    this.setState({ submit: { ...this.state.submit, ...submit } })
  }

  onChangeGuest(index: number, event: React.ChangeEvent<{ value: string }>) {
    const { guests } = this.state.form
    guests[index] = event.target.value
    this.setFormState({ guests })
  }

  onChangeBaby(event: React.ChangeEvent<{ value: string }>) {
    const val = (() => {
      switch (event.target.value) {
        case BOOLEAN.true: return true
        case BOOLEAN.false: return false
      }
      return false
    })()
    this.setFormState({ baby: val })
  }

  onChangeNumGuests(event: React.ChangeEvent<{ value: string }>) {
    const numGuests = parseInt(event.target.value, 10)
    const guests = [
      ...this.state.form.guests,
      ...(numGuests > this.state.form.guests.length ? Array(numGuests - this.state.form.guests.length).fill('') : []) // add
    ].slice(0, numGuests) // remove

    this.setFormState({ numGuests, guests })
  }

  onChangeComing(event: React.ChangeEvent<{ value: string }>) {
    const val = (() => {
      switch (event.target.value) {
        case BOOLEAN.true: return true
        case BOOLEAN.false: return false
      }
      return null
    })()
    this.setFormState({ coming: val })
  }

  onChangeFriday(event: React.ChangeEvent<{ value: string }>) {
    const val = (() => {
      switch (event.target.value) {
        case BOOLEAN.true: return true
        case BOOLEAN.false: return false
      }
      return null
    })()
    this.setFormState({ friday: val })
  }

  onChangeSunday(event: React.ChangeEvent<{ value: string }>) {
    const val = (() => {
      switch (event.target.value) {
        case BOOLEAN.true: return true
        case BOOLEAN.false: return false
      }
      return null
    })()
    this.setFormState({ sunday: val })
  }

  onChangeLocation(event: React.ChangeEvent<{ value: string }>) {
    const val = (() => {
      switch (event.target.value) {
        case AREA.blairgowrie: return AREA.blairgowrie
        case AREA.dundee: return AREA.dundee
        case AREA.other: return AREA.other
      }
      return null
    })()
    this.setFormState({ location: val })
  }

  onChangeMessage(event: React.ChangeEvent<{ value: string }>) {
    this.setFormState({ message: event.target.value })
  }

  async onSubmit() {
    const { submit } = this.state
    if (submit.sending || submit.status === SUBMIT_STATUS.SUCCESS) { return }

    this.setSubmitState({ sending: true, status: null })

    const status = await submitForm(this.state.form)
    this.setSubmitState({ sending: false, status })
  }

  render() {
    const { lang } = this.props
    const section = SECTIONS.rsvp

    const { form } = this.state

    return <section id={section} className={`${section}-section text-justify invert-colors`}>
      <h2 className="text-center">{t.sections[section](lang)}</h2>

      <div className="container">
        <div className="row">
          <div className="col-10 mx-auto">
            <form onSubmit={(e) => { e.preventDefault(); this.onSubmit() }}>
              <div className="form-group row">
                <label className="col-md-4">{t.numberofpeople(lang)}</label>
                <div className="col-md-8">
                  <input className="form-control col-md-2" onChange={this.onChangeNumGuests.bind(this)} type="number" min="1" max="8" value={form.numGuests} />
                </div>
              </div>

              {form.guests.map((x, i) => <div key={i} className="form-group row">
                <label className="col-md-4">{i === 0 ? t.guestnames(lang) : ''}</label>
                <div className="col-md-8">
                  <input className="form-control col" placeholder={`${t.guestnumber(lang)}${i + 1}`} value={x} onChange={e => this.onChangeGuest.bind(this)(i, e)} required={true} />
                </div>
              </div>)}

              <div className="form-group row">
                <label className="col-md-4">{t.attendance(lang)}</label>
                <div className="col-md-8">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="coming" value={BOOLEAN.true} onChange={this.onChangeComing.bind(this)} checked={form.coming === true} required={true} />
                    <label className="form-check-label">{t.yesimcoming(lang)}</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="coming" value={BOOLEAN.false} onChange={this.onChangeComing.bind(this)} checked={form.coming === false} required={true} />
                    <label className="form-check-label">{t.noimnotcoming(lang)}</label>
                  </div>
                </div>
              </div>

              {form.coming !== true ? null : <>
                <br />
                <h5>{t.meals(lang)}</h5>
                <div className="form-group row">
                  <label className="col-md-4">{t.fridaysnack(lang)}</label>
                  <div className="col-md-8">
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name="friday" value={BOOLEAN.true} onChange={this.onChangeFriday.bind(this)} checked={form.friday === true} required={true} />
                      <label className="form-check-label">{t.yes(lang)}</label>
                    </div>
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name="friday" value={BOOLEAN.false} onChange={this.onChangeFriday.bind(this)} checked={form.friday === false} required={true} />
                      <label className="form-check-label">{t.no(lang)}</label>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-4">{t.sundaybrunch(lang)}</label>
                  <div className="col-md-8">
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name="sunday" value={BOOLEAN.true} onChange={this.onChangeSunday.bind(this)} checked={form.sunday === true} required={true} />
                      <label className="form-check-label">{t.yes(lang)}</label>
                    </div>
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name="sunday" value={BOOLEAN.false} onChange={this.onChangeSunday.bind(this)} checked={form.sunday === false} required={true} />
                      <label className="form-check-label">{t.no(lang)}</label>
                    </div>
                  </div>
                </div>

                <h5>{t.accomodation(lang)}</h5>
                <div className="form-group row">
                  <label className="col-md-4">{t.whereareyoustaying(lang)}</label>
                  <div className="col-md-8">
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name="location" value={AREA.blairgowrie} onChange={this.onChangeLocation.bind(this)} checked={form.location === AREA.blairgowrie} required={true} />
                      <label className="form-check-label">
                        Blairgowrie
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name="location" value={AREA.dundee} onChange={this.onChangeLocation.bind(this)} checked={form.location === AREA.dundee} required={true} />
                      <label className="form-check-label">
                        Dundee
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name="location" value={AREA.other} onChange={this.onChangeLocation.bind(this)} checked={form.location === AREA.other} required={true} />
                      <label className="form-check-label">{t.othertellusbelow(lang)}</label>
                    </div>
                  </div>
                </div>

                <h5>{t.other(lang)}</h5>
                <div className="form-group row">
                <label className="col-md-4">{t.babys(lang)}</label>
                  <div className="col-md-8">
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name="baby" value={BOOLEAN.true} onChange={this.onChangeBaby.bind(this)} checked={form.baby} />
                      <label className="form-check-label">{t.yes(lang)}</label>
                    </div>
                    <div className="form-check-inline">
                      <input className="form-check-input" type="radio" name="baby" value={BOOLEAN.true} onChange={this.onChangeBaby.bind(this)} checked={!form.baby} />
                      <label className="form-check-label">{t.no(lang)}</label>
                    </div>
                  </div>
                </div>
              </>}

              <br />
              <h5>{t.comments(lang)}{form.coming ? ` ${t.commentsinfo(lang)}` : ''}</h5>
              <div className="form-group row">
                <div className="col">
                  <textarea className="form-control" maxLength={1000} value={form.message} onChange={this.onChangeMessage.bind(this)} placeholder={t.message(lang) as string} />
                </div>
              </div>

              <div className="form-group row">
                <div className="col text-center">
                  <button type="submit" className="btn btn-secondary mx-auto" disabled={this.state.submit.sending || this.state.submit.status === SUBMIT_STATUS.SUCCESS}>
                    {(() => {
                      if (this.state.submit.sending) { return t.sending(lang) }
                      if (this.state.submit.status === SUBMIT_STATUS.SUCCESS) { return t.thankyou(lang) }
                      return t.send(lang)
                    })()}
                  </button>
                </div>
              </div>

              <div className="form-group row">
                <div className="col text-center">
                  <div>
                    {(() => {
                      if (this.state.submit.sending) { return <i className="fa fa-fw fa-circle-o-notch fa-spin" /> }
                      if (this.state.submit.status === null) return null
                      if (this.state.submit.status === SUBMIT_STATUS.SUCCESS) { return <div className="text-success"><i className="fa fa-fw fa-check" /></div> }
                      return <div className="text-danger"><i className="fa fa-fw fa-times" /> {this.state.submit.status}</div>
                    })()}
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </section>
  }
}
