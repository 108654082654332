import * as React from 'react'

import { LANG, getLang, setLang } from '../i18n'

import GiftList from './GiftList'
import Header from './Header'
import Holiday from './Holiday'
import Upload from './Upload'
import Navbar from './Navbar'
import RSVP from './RSVP'
import Venue from './Venue'
import Wedding from './Wedding'

interface State {
  lang: LANG
}

export default class App extends React.Component<{}, State> {
  state: State = { lang: getLang() }

  setLang(lang: LANG) {
    setLang(lang)
    this.setState({ lang })
  }

  render() {
    const { lang } = this.state

    return <div id="page-top">

        <Navbar lang={lang} setLang={this.setLang.bind(this)} />
        <Header lang={lang} />

        <Upload lang={lang} />
        <Wedding lang={lang} />
        <Venue lang={lang} />
        <Holiday lang={lang} />
        <RSVP lang={lang} />
        <GiftList lang={lang} />

        {/* <!-- Footer --> */}
        <footer className="bg-dark text-center">
          <div className="container">
            <div className="brand">
              <i className="fa fa-fw fa-heart" />
                Isis & Hippo
              <i className="fa fa-fw fa-heart" />
            </div>
          </div>
      </footer>

    </div>
  }
}
