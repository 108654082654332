"use strict";

// Attributes in the format of htmlAtrribute: reactAttribute
const possibleStandardNames = ['accept', 'acceptCharset', 'acceptCharset', 'accessKey', 'action', 'allowFullScreen', 'alt', 'as', 'async', 'autoCapitalize', 'autoComplete', 'autoCorrect', 'autoFocus', 'autoPlay', 'autoSave', 'capture', 'cellPadding', 'cellSpacing', 'challenge', 'charSet', 'checked', 'children', 'cite', 'className', 'classID', 'className', 'cols', 'colSpan', 'content', 'contentEditable', 'contextMenu', 'controls', 'controlsList', 'coords', 'crossOrigin', 'dangerouslySetInnerHTML', 'data', 'dateTime', 'default', 'defaultChecked', 'defaultValue', 'defer', 'dir', 'disabled', 'disablePictureInPicture', 'disableRemotePlayback', 'download', 'draggable', 'encType', 'enterKeyHint', 'htmlFor', 'form', 'formMethod', 'formAction', 'formEncType', 'formNoValidate', 'formTarget', 'frameBorder', 'headers', // 'height',
'hidden', 'high', 'href', 'hrefLang', 'htmlFor', 'httpEquiv', 'httpEquiv', 'icon', 'id', 'innerHTML', 'inputMode', 'integrity', 'is', 'itemID', 'itemProp', 'itemRef', 'itemScope', 'itemType', 'keyParams', 'keyType', 'kind', 'label', 'lang', 'list', 'loop', 'low', 'manifest', 'marginWidth', 'marginHeight', 'max', 'maxLength', 'media', 'mediaGroup', 'method', 'min', 'minLength', 'multiple', 'muted', 'name', 'noModule', 'nonce', 'noValidate', 'open', 'optimum', 'pattern', 'placeholder', 'playsInline', 'poster', 'preload', 'profile', 'radioGroup', 'readOnly', 'referrerPolicy', 'rel', 'required', 'reversed', 'role', 'rows', 'rowSpan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'spellCheck', 'src', 'srcDoc', 'srcLang', 'srcSet', 'start', 'step', 'style', 'summary', 'tabIndex', // 'target',
'title', 'type', 'useMap', 'value', // 'width',
'wmode', 'wrap'];

const getHTMLProps = props => {
  // Gets all the React props
  return Object.fromEntries(Object.entries(props).filter(([key]) => possibleStandardNames.includes(key)));
};

module.exports = getHTMLProps;