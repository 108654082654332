import axios from 'axios'

// https://stackoverflow.com/questions/42533264/getting-error-promise-is-undefined-in-ie11
import { polyfill } from 'es6-promise'
polyfill()

const SUBMIT_URL = '/rsvp/submit'

export enum SUBMIT_STATUS {
  SUCCESS = 'SUCCESS',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  CLIENT_ERROR = 'CLIENT_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export const submitForm = async (form: object) => {
  try {
    console.log('submit form', { form })
    await axios.post(SUBMIT_URL, { form })
    console.log('submit form success')
    return SUBMIT_STATUS.SUCCESS
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      console.log('submit form error', { status: err.response.status, data: err.response.data })

      if (err.response.status === 429) { return SUBMIT_STATUS.TOO_MANY_REQUESTS }
      if (err.response.status >= 500) { return SUBMIT_STATUS.SERVER_ERROR }
      if (err.response.status >= 400) { return SUBMIT_STATUS.CLIENT_ERROR }

      return SUBMIT_STATUS.UNKNOWN_ERROR
    }

    // https://github.com/axios/axios/issues/383
    if (err instanceof Error && err.message === 'Network Error') { return SUBMIT_STATUS.NETWORK_ERROR }

    throw err
  }
}
